<template>
    <div>
        <div class="form-page" v-if="Enabled">

            <choiceLang />

            <div class="form-title">
                <span class="text">
                    <span v-if="lang === 'zh'">{{ externalHealthQnrDesign.TitleChinese }}</span>
                    <span v-if="lang === 'en'">{{ externalHealthQnrDesign.TitleEnglish }}</span>  
                </span>

            <div class="page-number">
                <span>{{ currentPage }}</span
                >/{{ categories.length + 2 }}
            </div>
            </div>

            <div class="form-wrap">
            <ValidationObserver v-slot="{ valid, changed, validated }" ref="form">
                <form @submit.prevent="addPersonalHealthQuestionnaire">
                    <div v-if="currentPage === 1" class="page">
                        
                        <div class="title">
                            <span>
                                <span v-if="lang === 'zh'">黃卡編號</span>
                                <span v-if="lang === 'en'">Card ID</span>  
                            </span>
                        </div>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="field">
                                <input type="text" :placeholder="i18n('PlaceHolder.CardID')" v-model="applicantInfo.NoCardId" maxlength="50"/>
                            </div>
                            <span v-if="errors[0]" class="text-danger">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>

                        <div class="title">
                            <span>
                                <span v-if="lang === 'zh'">公司名</span>
                                <span v-if="lang === 'en'">Company Name</span>  
                            </span>
                        </div>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="field">
                                <input type="text" :placeholder="i18n('PlaceHolder.CompanyName')" v-model="applicantInfo.CompanyName" maxlength="50"/>
                            </div>
                            <span v-if="errors[0]" class="text-danger">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>

                        <div class="title">
                            <span>
                                <span v-if="lang === 'zh'">姓名</span>
                                <span v-if="lang === 'en'">Name</span>  
                            </span>
                        </div>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="field">
                                <input type="text" :placeholder="i18n('PlaceHolder.Name')" v-model="applicantInfo.Name" maxlength="20"/>
                            </div>
                            <span v-if="errors[0]" class="text-danger">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>

                        <div class="title">
                            <span>
                                <span v-if="lang === 'zh'">身分證字號/護照號碼</span>
                                <span v-if="lang === 'en'">ID Number</span>  
                            </span>
                        </div>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="field">
                                <input type="text" :placeholder="i18n('PlaceHolder.IdNumber')" v-model="applicantInfo.IdNumber" maxlength="20"/>
                            </div>
                            <span v-if="errors[0]" class="text-danger">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>

                        <div class="title">
                            <span>
                                <span v-if="lang === 'zh'">連絡電話</span>
                                <span v-if="lang === 'en'">Telephone Number</span>  
                            </span>
                        </div>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="field">
                                <input type="text" :placeholder="i18n('PlaceHolder.TelephoneNumber')" v-model="applicantInfo.TelephoneNumber" maxlength="20"/>
                            </div>
                            <span v-if="errors[0]" class="text-danger">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>

                        <div class="title">
                            <span>
                                <span v-if="lang === 'zh'">居住地</span>
                                <span v-if="lang === 'en'">Current Residence Place</span>  
                            </span>
                        </div>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="field">
                                <input type="text" :placeholder="i18n('PlaceHolder.CurrentResidencePlace')" v-model="applicantInfo.CurrentResidencePlace" maxlength="100"/>
                            </div>
                            <span v-if="errors[0]" class="text-danger">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>

                        <div class="title">
                            <span>
                                <span v-if="lang === 'zh'">訪客類別</span>
                                <span v-if="lang === 'en'">Visitor Type</span>  
                            </span>
                        </div>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <label class="checkbox" v-for="option in visitorTypes" :key="option.OptionsName_zhTW">
                                <input type="radio" :value="option.OptionsName_zhTW" v-model="applicantInfo.VisitorType" maxlength="50"/>
                                <span class="text">
                                    <span v-if="lang === 'zh'">{{ option.OptionsName_zhTW }}</span>
                                    <span v-if="lang === 'en'">{{ option.OptionsName_enUS }}</span>
                                </span>
                            </label>

                            <span v-if="errors[0]" class="text-danger">
                                {{ errors[0] }}
                            </span>
                        </ValidationProvider>

                        <div class="field" v-if="applicantInfo.VisitorType === '駐廠廠商'">
                            <div class="title">
                                <span>
                                    <span v-if="lang === 'zh'">駐廠廠商</span>
                                    <span v-if="lang === 'en'">Factory vendor</span>  
                                </span>
                            </div>
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <label class="checkbox" v-for="option in vendors" :key="option.OptionsName_zhTW">
                                    <input type="radio" :value="option.OptionsName_zhTW" v-model="applicantInfo.Vendor" maxlength="50"/>
                                    <span class="text">
                                        <span v-if="lang === 'zh'">{{ option.OptionsName_zhTW }}</span>
                                        <span v-if="lang === 'en'">{{ option.OptionsName_enUS }}</span>
                                    </span>
                                </label>

                                <span v-if="errors[0]" class="text-danger">
                                    {{ errors[0] }}
                                </span>
                            </ValidationProvider>
                        </div>

                        
                        
                    </div>

                    <div v-if="currentPage === 2" class="page">
                        <div class="title">
                        <!-- <span>一、最新疫調足跡史確認 </span> -->
                        <!-- <span>一、今日無公告新足跡 <br>There is no announcement of the latest footprints today.</span> -->
                            <span>
                                <span v-if="lang === 'zh'">公告足跡史及重點疫調項目</span>
                                <span v-if="lang === 'en'">Latest footprints and important survey items</span>
                            </span>
                        </div>

                        
                        <ValidationProvider rules="required" v-slot="{ errors }">

                        <label class="checkbox" v-for="item in newFootprints" :key="item.ID">
                            <input type="checkbox" :value="item.Chinese" v-model="questionnaire.Footprint" @change="changeLatestFootprints(item.Chinese)" />
                            <span class="text">
                                <span v-if="lang === 'zh'">{{ item.Chinese }} </span>
                                <span v-if="lang === 'en'">{{ item.English }}</span>
                            </span>
                        </label>

                        <span v-if="errors[0]" class="text-danger">
                            {{ errors[0] }}
                        </span>
                        </ValidationProvider>
                    </div>

                    <div v-for="(category, index) in categories" :key="category.Guid" class="page">
                        <div v-if="currentPage === (index+3)">
                                <div class="title">
                                    <span v-if="lang === 'zh'">{{ category.SurveyChinese }}</span>
                                    <span v-if="lang === 'en'">{{ category.SurveyEnglish }}</span>
                                </div>

                                <div v-for="question in category.ContentJson" :key="question.Guid">
                                    <div class="desc">
                                        <span v-if="lang === 'zh'">{{ question.FieldName_zhTW }}</span>
                                        <span v-if="lang === 'en'">{{ question.FieldName_enUS }}</span>
                                    </div>

                                    <!-- input -->
                                    <ValidationProvider v-if="question.FieldType === 'Input'" :rules="{ required: question.Required !== null && question.Required }" v-slot="{ errors }">
                                        <div class="field">
                                            <input type="text" v-model="question.Value" maxlength="10000" />
                                        </div>
                                        <span v-if="errors[0]" class="text-danger">
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>
                                    
                                    <!-- textarea -->
                                    <ValidationProvider v-if="question.FieldType === 'TextArea'" :rules="{ required: question.Required !== null && question.Required }" v-slot="{ errors }">
                                        <div class="field">
                                            <textarea style="min-height: 112px" v-model="question.Value" maxlength="10000"></textarea>
                                        </div>
                                        <span v-if="errors[0]" class="text-danger">
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>

                                    <!-- radio -->
                                    <ValidationProvider v-if="question.FieldType === 'Radio'" :rules="{ required: question.Required !== null && question.Required }" v-slot="{ errors }">
                                        <label class="checkbox" v-for="option in question.Option" :key="option.OptionsName_zhTW">
                                            <input type="radio" :value="option.OptionsName_zhTW" v-model="question.Value" />
                                            <span class="text">
                                                <span v-if="lang === 'zh'">{{ option.OptionsName_zhTW }}</span>
                                                <span v-if="lang === 'en'">{{ option.OptionsName_enUS }}</span>
                                            </span>
                                        </label>

                                        <span v-if="errors[0]" class="text-danger">
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>

                                    <!-- checkbox -->
                                    <ValidationProvider v-if="question.FieldType === 'Checkbox'" :rules="{ required: question.Required !== null && question.Required }" v-slot="{ errors }">
                                        <label class="checkbox symptom-item" v-for="option in question.Option" :key="option.OptionsName_zhTW">
                                            <input type="checkbox" :value="option.OptionsName_zhTW" v-model="question.Value" />
                                            <span class="text">
                                                <span v-if="lang === 'zh'">{{ option.OptionsName_zhTW }}</span>
                                                <span v-if="lang === 'en'">{{ option.OptionsName_enUS }}</span>
                                            </span>
                                        </label>
                                        <span v-if="errors[0]" class="text-danger">
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>

                                    <!-- date -->
                                    <ValidationProvider v-if="question.FieldType === 'DateTime'" :rules="{ required: question.Required !== null && question.Required }" v-slot="{ errors }">
                                        <!-- <div class="field">
                                            <el-date-picker type="date" value-format="yyyy-MM-dd" :picker-options="datePickerOptions" v-model="question.Value">
                                            </el-date-picker>
                                        </div> -->
                                        <div class="field">
                                            <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="question.Value">
                                            </el-date-picker>
                                        </div>
                                        <span v-if="errors[0]" class="text-danger">
                                            {{ errors[0] }}
                                        </span>
                                    </ValidationProvider>

                                    

                                </div>



                        </div>
                    </div>

                    
                    <div class="button-wrap">
                        <button
                        type="button"
                        v-show="currentPage > 1"
                        @click.self="currentPage -= 1"
                        class="btn btn-secondary"
                        >
                        {{ i18n("Buttons.PreviousPage") }}
                        </button>
                        <button
                        type="submit"
                        v-show="currentPage < (categories.length+2)"
                        class="btn btn-yellow"
                        >
                        {{ i18n("Buttons.NextPage") }}

                        </button>
                        <button type="submit" v-show="currentPage === (categories.length+2)" class="btn btn-yellow">{{ i18n("Buttons.Submit") }}</button>
                        <span style="cursor: default;width: auto;" v-if="(changed && !valid && validated) || (!changed && !valid && validated)" class="btn text-danger">{{ i18n("vee-validations.Alert") }}</span>
                    </div>
                </form>
            </ValidationObserver>
            </div>
        </div>

        <div class="form-page" v-else>
            <div class="prohibit">
                <img src="@/assets/img/prohibit.png" alt="">
                <p>此問卷尚未開放</p>
            </div>
        </div>


        <!-- 預覽 Modal -->
        <div
            id="previewModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            data-backdrop="static"
        >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header title">
                    <span>填答結果</span>
                </div>
                <div class="modal-body">
                    <p class="text-danger">【您有填寫以下內容，如需修改請回問卷調整】</p>

                    <div class="mb-4">
                        <p class="category-title">1. 公告足跡史及重點疫調項目</p>
                        <span v-for="(item, index) in questionnaire.Footprint" :key="item">
                            <span v-if="index > 0">、</span>
                            {{item}}
                        </span>
                    </div>

                    <div v-for="(category, categoryIndex) in categories" :key="category.Guid">
                        <p class="category-title">{{ categoryIndex + 2 }}.  {{ category.SurveyChinese }}</p>

                        <div v-for="(ques, quesIndex) in category.ContentJson" :key="ques.Guid">
                            <p v-if="ques.FieldType === 'Checkbox'">
                                {{ categoryIndex + 2 }}-{{ quesIndex + 1 }}. {{ ques.FieldName_zhTW }}： 
                                <span v-for="(item, index) in ques.Value" :key="item">
                                    <span v-if="index > 0">、</span>
                                    {{item}}
                                </span>
                            </p>
                            <p v-else-if="ques.FieldType !== 'Remark'">
                                {{ categoryIndex + 2 }}-{{ quesIndex + 1 }}. {{ ques.FieldName_zhTW }}： {{ ques.Value }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        type="reset"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        {{ i18n("Buttons.Cancel") }}
                    </button>
                    <button
                        class="btn btn-success"
                        data-dismiss="modal"
                        :key="index"
                        @click.prevent.once="submitQuestionnaire"
                    >
                        確認送出
                    </button>
                </div>
            </div>
            
        </div>
        </div>
    </div>
    
    
</template>

<script>
/* global $ */
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

import { apiGetStaffName, apiGetExternalFootprintAll } from '@/apis/common'
import { apiAddExternalHealthQuestionnaire, apiGetExternalPHSurveyItemsAll, apiCheckExternalHealthQnrEnabled } from '@/apis/questionnaire'

import choiceLang from '@/components/common/choiceLang.vue'


export default {
  components: {
    choiceLang
  },
  data() {
    return {
      currentPage: 1,
      active: 0,

      check: null,
      check1: null,
      check2: null,
      symptom: [  //  症狀
        {
          key: '1',
          name: '發燒 fever',
          value: '發燒'
        },
        {
          key: '2',
          name: '喉嚨痛 sore throat',
          value: '喉嚨痛'
        },
        {
          key: '3',
          name: '咳嗽 cough',
          value: '咳嗽'
        },
        {
          key: '4',
          name: '嗅/味覺改變 change in taste/smell',
          value: '嗅/味覺改變'
        },
        {
          key: '5',
          name: '流鼻水 runny nose',
          value: '流鼻水'
        },
        {
          key: '6',
          name: '全身痠痛 body pain',
          value: '全身痠痛'
        },
        {
          key: '7',
          name: '胸痛/胸悶 chest pain/tightness',
          value: '胸痛/胸悶'
        },
        {
          key: '8',
          name: '呼吸困難 hard breathing',
          value: '呼吸困難'
        },
        {
          key: '9',
          name: '頭痛 head ache',
          value: '頭痛'
        },
        {
          key: '10',
          name: '接種疫苗後發燒 fever after vaccination',
          value: '接種疫苗後發燒'
        },
        {
          key: '1',
          name: '其他 others',
          value: '其他'
        },
        {
          key: '12',
          name: '以上皆無 None of the above',
          value: '以上皆無'
        },
    
      ],
      RapidTestReasonOption: [
        {
          name: '個人健康需求',
          Enname: 'Personal health needs',
        },
        {
          name: '涉及高風險職業場所(服務業、醫院、學校)',
          Enname: 'I have been to place of high-risk occupation (service industry, hospital, school…, etc.)',
        },
        {
          name: '跨縣市移動',
          Enname: 'I have moved cross county',
        },
        {
          name: '高風險足跡重疊',
          Enname: 'I have had footprints of confirmed COVID-19 cases',
        },
        {
          name: '接觸外部廠商',
          Enname: 'I have had contact with outside manufacturers',
        },
        {
          name: '群聚風險(喪禮、市場、聚會)',
          Enname: 'I have had the risk of cluster(funeral, market, party)',
        },
        {
          name: '搭乘大眾交通工具通勤',
          Enname: 'I have taken the public transportation for commuting',
        },
      ],
      newFootprints: [],
      staffInfo: {
        BadgeNo: null,  //  工號
        Name: null,
      },

      applicantInfo: {},
      visitorTypes: [
        {
            OptionsName_zhTW: '客戶',
            OptionsName_enUS: 'Customer'
        },
        {
            OptionsName_zhTW: '訪客',
            OptionsName_enUS: 'Visitor'
        },
        {
            OptionsName_zhTW: '送提貨廠商',
            OptionsName_enUS: 'Pick up/Deliver the goods'
        },
        {
            OptionsName_zhTW: '駐廠廠商',
            OptionsName_enUS: 'Factory vendor'
        },
        {
            OptionsName_zhTW: '設備商',
            OptionsName_enUS: 'Machine supplier'
        },
        {
            OptionsName_zhTW: '施工廠商',
            OptionsName_enUS: 'Construct supplier'
        },
        {
            OptionsName_zhTW: '求職者',
            OptionsName_enUS: 'Applicants for apply jobs'
        },
        {
            OptionsName_zhTW: '新人報到',
            OptionsName_enUS: 'New Employee'
        },
      ],
      vendors: [
        {
            OptionsName_zhTW: '嘉苗',
            OptionsName_enUS: '嘉苗'
        },
        {
            OptionsName_zhTW: '威合',
            OptionsName_enUS: '威合'
        },
        {
            OptionsName_zhTW: '歐艾斯',
            OptionsName_enUS: '歐艾斯'
        },
        {
            OptionsName_zhTW: '北氧',
            OptionsName_enUS: '北氧'
        },
        {
            OptionsName_zhTW: '田中',
            OptionsName_enUS: '田中'
        },
        {
            OptionsName_zhTW: '威策電腦',
            OptionsName_enUS: 'Well Take Computer'
        },
      ],

      questionnaire: {
        // attendance: '',
        temperature : '',
        temperatureByFamily: '',

        // latestFootprints: [],
        Footprint: [],

        DutyStatus: '',
        Leave: '',
        RapidTestWish: null,
        RapidTestDate: null,
        RapidTestReason: [],
        VaccinationStatus: '',
        FirstVaccinationDate: null,
        SecondVaccinationDate: null,
        DailySurvey: [],
        tempDailySurvey: [],  //  暫存

        //  三
        HealthConditionByMyself: [],
        OtherByMyself: null,
        ControlList: false,
        ForeheadByMyself: null,
        EarByMyself: null,
        ArmpitByMyself: null,
        BeginDateByMyself: null,
        BodyTemperatureByMyself: [],
        // SeeDoctorByMyself: false,
        SeeDoctorByMyself: [],
        SeeDoctorDateByMyself: null,
        FindingsByMyself: null,
        HospitalNameByMyself: null,

        MeasuringPosition: [],

        //  四
        HealthConditionByFamily: [],
        OtherByFamily: null,
        BodyTemperatureByFamily: null,
        ForeheadByFamily: null,
        EarByFamily: null,
        ArmpitByFamily: null,
        BeginDateByFamily: null,
        ComeBackDate: null,
        SeeDoctorByFamily: [],
        SeeDoctorDateByFamily: null,
        FindingsByFamily: null,
        HospitalNameByFamily: null,

        MeasuringPositionByFamily: [],

        // 五
        HaveComments: false,
        Comments: null,
      },
      otherByMyself: false,
      otherByFamily: false,
      currentDate: '',
      datePickerOptions: {
        disabledDate (date) {
          return date > new Date()
        }
      },
      futureDatePickerOptions: {
        disabledDate (date) {
          return date < new Date()
        }
      },
      pickerOptions: {
        disabledDate(time) {
          return new Date(time).getDay() === 1;
        }
      },
      pickerWeekendOptions: {
        disabledDate(time) {
          return new Date(time).getDay() === 0 || new Date(time).getDay() === 6;
        }
      },

      categories: [],
      Enabled: false,   //  是否啟用
      index: 0, //  避免被once後，無法再次送出
    };
  },
  watch: {
    'questionnaire.MeasuringPosition': function() {
      if(this.questionnaire.MeasuringPosition.length === 2) {
        this.questionnaire.MeasuringPosition.splice(0, 1)
      }
    },
    'questionnaire.temperature': function() {
      if(this.questionnaire.MeasuringPosition[0] === '額溫') {
        this.questionnaire.ForeheadByMyself =  this.questionnaire.temperature
        this.questionnaire.EarByMyself = null
        this.questionnaire.ArmpitByMyself = null
      } else if(this.questionnaire.MeasuringPosition[0] === '耳溫') {
        this.questionnaire.EarByMyself = this.questionnaire.temperature
        this.questionnaire.ForeheadByMyself = null
        this.questionnaire.ArmpitByMyself = null
      } else if(this.questionnaire.MeasuringPosition[0] === '腋溫') {
        this.questionnaire.ArmpitByMyself = this.questionnaire.temperature
        this.questionnaire.ForeheadByMyself = null
        this.questionnaire.EarByMyself = null
      }
    },
    'questionnaire.BodyTemperatureByMyself': function() {
      if(this.questionnaire.BodyTemperatureByMyself.length === 2) {
        this.questionnaire.BodyTemperatureByMyself.splice(0, 1)
      }
    },
    'questionnaire.SeeDoctorByMyself': function() {
      if(this.questionnaire.SeeDoctorByMyself.length === 2) {
        this.questionnaire.SeeDoctorByMyself.splice(0, 1)
      }
    },
    'questionnaire.MeasuringPositionByFamily': function() {
      if(this.questionnaire.MeasuringPositionByFamily.length === 2) {
        this.questionnaire.MeasuringPositionByFamily.splice(0, 1)
      }
      
    },
    'questionnaire.temperatureByFamily': function() {
      if(this.questionnaire.MeasuringPositionByFamily[0] === '額溫') {
        this.questionnaire.ForeheadByFamily =  this.questionnaire.temperatureByFamily
        this.questionnaire.EarByFamily = null
        this.questionnaire.ArmpitByFamily = null
      } else if(this.questionnaire.MeasuringPositionByFamily[0] === '耳溫') {
        this.questionnaire.EarByFamily = this.questionnaire.temperatureByFamily
        this.questionnaire.ForeheadByFamily = null
        this.questionnaire.ArmpitByFamily = null
      } else if(this.questionnaire.MeasuringPositionByFamily[0] === '腋溫') {
        this.questionnaire.ArmpitByFamily = this.questionnaire.temperatureByFamily
        this.questionnaire.ForeheadByFamily = null
        this.questionnaire.EarByFamily = null
      }
    },
    'questionnaire.SeeDoctorByFamily': function() {
      if(this.questionnaire.SeeDoctorByFamily.length === 2) {
        this.questionnaire.SeeDoctorByFamily.splice(0, 1)
      }
    },
    'questionnaire.Comments': function() {
      if(this.questionnaire.Comments.length > 0) {
        this.questionnaire.HaveComments = true
      } else {
        this.questionnaire.HaveComments = false
      }
    },
    'currentPage': function() {
        this.$refs.form.reset();

        this.goTop();

    },
    'questionnaire.HealthConditionByMyself': function() {
      let isHave = this.questionnaire.HealthConditionByMyself.find(item => {
        return item === '其他'
      })

      if(isHave !== undefined) {
        this.otherByMyself = true
      } else {
        this.otherByMyself = false
      }

      // if(this.questionnaire.HealthConditionByMyself.indexOf('以上皆無') !== -1) {
      //   this.questionnaire.HealthConditionByMyself = ['以上皆無']
      // }

    },
    'questionnaire.HealthConditionByFamily': function() {
      let isHave = this.questionnaire.HealthConditionByFamily.find(item => {
        return item === '其他'
      })

      if(isHave !== undefined) {
        this.otherByFamily = true
      } else {
        this.otherByFamily = false
      }

      // if(this.questionnaire.HealthConditionByFamily.indexOf('以上皆無') !== -1) {
      //   this.questionnaire.HealthConditionByFamily = ['以上皆無']
      // }

    },
  },
  computed: {
    ...mapGetters(['externalHealthQnrDesign', 'lang'])
  },
  methods: {
    ...mapActions(['getExternalHealthQnrDesign']),
    ///////////////////////////  多國語系  ///////////////////////////////////////////
    i18n (keyStr) {
      return this.$t(keyStr);
    },
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    getStaffName() {
      apiGetStaffName({
        badgeNo: this.staffInfo.BadgeNo
      }).then((response) => {

        if(response.status === 200) {
          this.staffInfo.Name = response.data
        }
      })
      .catch(() => {
        this.staffInfo.BadgeNo = null
        this.$refs.form.reset();
      })
    },
    async addPersonalHealthQuestionnaire() {

      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return
      }

      if(this.currentPage === (this.categories.length+2)) {

        await this.checkExternalHealthQnrEnabled()

        if(this.Enabled) {
            $('#previewModal').modal('show')
        } else {
            this.$notify({
                title: '錯誤',
                message: '此問卷尚未開放',
                type: 'error',
                duration: 3500
            });
        }

      } else {
        this.currentPage += 1;
      }
      
    },
    async submitQuestionnaire() {
        let obj = this.categories.map(category => {
            let tempQuestion = []
            category.ContentJson.map(question => {
                if(question.FieldType === 'Checkbox') {
                    tempQuestion.push({
                        ...question,
                        Value: question.Value.join('、')
                    })
                } else {
                    tempQuestion.push({
                        ...question
                    })
                }
            })

            return {
                ...category,
                ContentJson: tempQuestion
            }
        })

        await apiAddExternalHealthQuestionnaire({
            ...this.applicantInfo,
            Vendor: this.applicantInfo.VisitorType === "駐廠廠商" ? this.applicantInfo.Vendor : '',
            Footprint: this.questionnaire.Footprint,
            DataContent: obj
        }).then((response) => {

            if(response.status === 200) {
                this.$notify({
                    title: '成功',
                    message: '已成功送出',
                    type: 'success',
                    duration: 3500
                });

                this.$router.push({ name: 'Finish',  params: { staff: JSON.stringify(this.applicantInfo), external: true }})
            }
        })
        .catch(() => {
          this.index++ ;
        })
    },
    goTop() {
      $('html,body').animate({ scrollTop: 0 }, 'slow');   /* 返回到最頂上 */
      return false;
    },
    changeDailySurvey(item) {
      if((item !== '以上皆無') && (this.questionnaire.DailySurvey.indexOf('以上皆無') !== -1)) {
        this.questionnaire.DailySurvey.splice(0, 1)
      } else if(item !== '以上皆無') {
        // this.questionnaire.DailySurvey.slice(1)
      } else if((item === '以上皆無') && (this.questionnaire.DailySurvey.indexOf('以上皆無') !== -1)) {
        this.questionnaire.DailySurvey = ['以上皆無']
      }
      // if(this.questionnaire.DailySurvey.indexOf('以上皆無') !== -1) {
      //   this.questionnaire.DailySurvey = ['以上皆無']
      // } 
    },
    changeHealthConditionByMyself(item) {
      if((item !== '以上皆無') && (this.questionnaire.HealthConditionByMyself.indexOf('以上皆無') !== -1)) {
        this.questionnaire.HealthConditionByMyself.splice(0, 1)
      } else if(item !== '以上皆無') {
        // this.questionnaire.HealthConditionByMyself.slice(1)
      } else if((item === '以上皆無') && (this.questionnaire.HealthConditionByMyself.indexOf('以上皆無') !== -1)) {
        this.questionnaire.HealthConditionByMyself = ['以上皆無']
      }
      // if(this.questionnaire.HealthConditionByMyself.indexOf('以上皆無') !== -1) {
        // this.questionnaire.HealthConditionByMyself = ['以上皆無']
      // }
    },
    changeHealthConditionByFamily(item) {
      if((item !== '以上皆無') && (this.questionnaire.HealthConditionByFamily.indexOf('以上皆無') !== -1)) {
        this.questionnaire.HealthConditionByFamily.splice(0, 1)
      } else if(item !== '以上皆無') {
        // this.questionnaire.HealthConditionByMyself.slice(1)
      } else if((item === '以上皆無') && (this.questionnaire.HealthConditionByFamily.indexOf('以上皆無') !== -1)) {
        this.questionnaire.HealthConditionByFamily = ['以上皆無']
      }
      // if(this.questionnaire.HealthConditionByFamily.indexOf('以上皆無') !== -1) {
      //   this.questionnaire.HealthConditionByFamily = ['以上皆無']
      // }
    },
    changeLatestFootprints(item) {
      if((item !== '以上皆無') && (this.questionnaire.Footprint.indexOf('以上皆無') !== -1)) {
        this.questionnaire.Footprint.splice(0, 1)
      } else if(item !== '以上皆無') {
        // this.questionnaire.latestFootprints.slice(1)
      } else if((item === '以上皆無') && (this.questionnaire.Footprint.indexOf('以上皆無') !== -1)) {
        this.questionnaire.Footprint = ['以上皆無']
      }
      // if(this.questionnaire.latestFootprints.indexOf('以上皆無') !== -1) {
        // this.questionnaire.latestFootprints = ['以上皆無']
      // }
    },
    getFootprintAll() {
      apiGetExternalFootprintAll().then((response) => {
        if(response.status === 200) {
          this.newFootprints = response.data
          this.newFootprints.push({
            ID: response.data.length+1,
            Chinese: '以上皆無',
            English: 'None of the above',
          })
        }
      })
    },
    getPHSurveyItemsAll() {     //  取得維護的題目  
        
      apiGetExternalPHSurveyItemsAll().then((response) => {

        if(response.status === 200) {

          this.categories = response.data.map(category => {
              let tempQuestion = []
              category.ContentJson.map(question => {
                  if(question.FieldType === 'Checkbox') {
                      tempQuestion.push({
                          ...question,
                          Value: []
                      })
                  } else {
                      tempQuestion.push({
                          ...question
                      })
                  }
              })

              return {
                  ...category,
                  ContentJson: tempQuestion
              }
          })

        }
      })
    },
    async checkExternalHealthQnrEnabled() {     //  確認疫調問卷是否開放  
        
        await apiCheckExternalHealthQnrEnabled().then((response) => {

            if(response.status === 200) {
                this.Enabled = response.data
            }
        })
    },
  },
  created() {
    this.currentDate = moment().format('YYYY-MM-DD');

    this.checkExternalHealthQnrEnabled();
  },
  mounted() {
    this.getFootprintAll()
    this.getPHSurveyItemsAll()

    this.getExternalHealthQnrDesign()

  },
  destroyed() {
    $(".modal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
  },
};
</script>

<style lang="scss" scoped>
.form-page {
  background-color: #dae6f2;
  text-align: center;
  min-height: 100vh;
  padding: 90px 20px;
//   background-image: url("~@/assets/img/bg.png");
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding: 60px 30px;
  }

  .form-title {
    width: 400px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 30px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    img {
      width: 100%;
      max-width: 280px;
      margin: 30px;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .text {
      background-image: url("~@/assets/img/title_bg.png");
      padding: 10px 20px;
      border-radius: 44px;
      color: #000;
      display: inline-block;
      font-size: 2em;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        font-size: 1em;
      }
    }
    .page-number {
      span {
        font-size: 2em;
      }
    }
  }
  .form-wrap {
    width: 550px;
    display: inline-block;
    vertical-align: middle;
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .title {
      text-align: center;
      margin-bottom: 15px;

      span {
        display: inline-block;
        background: linear-gradient(rgba(#fff, 0) 60%, #fff 0%) !important;
        font-weight: bold;
        padding: 0 15px;
      }
    }

    .desc {
      font-size: 0.9em;
      line-height: 200%;
      margin-top: 15px;
      margin-bottom: 10px;
      text-align: left !important;
    }

    .field {
      margin-bottom: 10px;
      input {
        border: none;
        padding: 0 10px;
        line-height: 40px;
        width: 100%;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2);
        &:focus {
          outline: none;
        }
      }

      .el-date-editor{
        width:100%;
      }
    }

    .checkbox {
      display: block;
      cursor: pointer;
      text-align: left;

      .text {
        &:after {
          content: "\f058";
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          color: #b6d3b6;
          position: absolute;
          left: 10px;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%);
        //   color: #d7e995;
          color: #000;
          font-size: 1.2em;
        }

        position: relative;
        // background-color: #97bd97;
        background-color: #fff;
        line-height: 40px;
        display: block;
        padding: 0 10px;
        padding-left: 40px;
        border-radius: 35px;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        // color: #fff;
        color: #000;
      }

      input {
        display: none;
        &:checked {
          & + .text {
            // background-color: #039577;
            // color: #fff;
            background-color: #f6cf74;
            color: #000;
            &:after {
            //   color: #fff;
                color: #000;
            }
          }
        }
      }
    }

    textarea {
      border: none;
      padding: 10px;
      width: 100%;
      margin-bottom: 10px;
      border-radius: 5px;
      box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.2);
    }

    .button-wrap {
      margin-top: 30px;
    }
  }
}

.btn {
  width: 190px;
  margin: .4rem;
}

.btn-yellow {
    background-color: #f6cf74 !important;
    border-color: #f6cf74 !important;
    cursor: pointer;
}

.ban {
  cursor: not-allowed !important;
}

.prohibit {
    width: 500px;
    display: inline-block;
    // vertical-align: middle;
    padding: 0 30px;

    @media screen and (max-width: 767px) {
        width: 100%;
    }

    img {
        width: 100%;
        // max-width: 280px;
        margin-bottom: 30px;
    }

    p {
        width: 100%;
        font-size: 1.25rem;
        color: #5a5c69!important;
        font-weight: 900;
    }
}

#previewModal {
    .modal-dialog {
        min-width: 700px;

        @media screen and (max-width: 767px) {
            width: 100%;
            min-width: auto;
            font-size: .7em;
        }        
    }

    .title {
        text-align: center;
        background-color: #636f83;
        padding: 20px 0;
        border-radius: 0px;
        color: #fff;
        justify-content: center;
    }

    .category-title {
        font-weight: 900;
    }

    .btn {
        width: auto;
    }
}


</style>
